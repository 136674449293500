<template>
   <div class="record-audit-wrapper">
       <div class="fiexd-cover">
           <div class="record-audit-cover flex-box-column">
           <div class="hd">
                <van-nav-bar 
                left-arrow 
                safe-area-inset-top 
                :title="recordTypeTitle" 
                :right-text="rightTypeBtn"
                @click-left="back"
                @click-right="submitRecordData"
                >
                </van-nav-bar>
           </div>
           <div class="bd record-audit__content">
               <div class="record-audit__content-title van-hairline--bottom" @click="onCopy({type:'all'})">
                   <div class="house-name">{{houseName || '获取中'}}</div>
                   <div class="recordType van-hairline--surround"
                    :class="{
                        
                        await:approvalStatus=='RECORDSTART' || approvalStatus=='RECORDSTANDBY',
                        over:approvalStatus=='RECORDCOMPLETE',
                        visit:approvalStatus=='VISIT' || approvalStatus== 'UNVISIT',
                        ctd:approvalStatus== 'MAKEBARGAIN' || approvalStatus== 'MAKEBARGAINCOMPLETE',
                        //退房或者是无效
                        end:approvalStatus=='RECORDCLOSE' || approvalStatus == 'MAKEBARGAINCLOSE',

                    }">{{getApprovalStatusText(approvalStatus)}}</div>
               </div>
               <div class="record-audit__content-info">
                    <van-cell title="报备时间" :value="createTime" 
                    @click="onCopy({value:createTime,text:'报备时间'})" />
                    <van-cell title="客户名称" 
                    :value="customerName" 
                    @click="onCopy({value:customerName,text:'客户名称'})"/>
                    <van-cell title="客户电话" :value="customerPhone" 
                    @click="onCopy({value:customerPhone,text:'客户电话'})"/>
                    <van-cell title="客户性别" :value="customerSex == 1 ? '男' : customerSex == 2?  '女' :''" 
                    @click="onCopy({value:customerSex == 1 ? '男' : customerSex == 2?  '女' :'',text:'客户性别'})"/>
                    <van-cell title="客户相关购房资质" class="flex-box-column" v-if="customerPic.length>0">
                        <div class="img-cover">
                            <div class="img-item" v-for="(item,index) in customerPic" :key="index" @click="imageShowOpen(index,customerPic)">
                                <img :src="item|$imageUrl" alt="">
                            </div>
                        </div>
                    </van-cell>
                    <van-cell title="预计到访时间" :value="estimatedVisitTime" 
                    @click="onCopy({value:estimatedVisitTime,text:'到访时间',key:'estimatedVisitTime'})"/>
                    <van-cell title="到访人数" :value="visitorNum"
                    @click="onCopy({value:visitorNum,text:'到访人数'})" />
                    <van-cell title="分销公司" :value="distributorName" 
                    @click="onCopy({value:distributorName,text:'分销公司名称'})"/>
                    <van-cell title="带看人" :value="dkName"
                    @click="onCopy({value:dkName,text:'带看人姓名'})"/>
                    <van-cell title="带看人电话" :value="dkPhone"
                    @click="onCopy({value:dkPhone,text:'带看人电话'})"/> 
                    <van-cell title="指定接待" :value="reception"
                    @click="onCopy({value:reception,text:'指定接待'})"/>
                    <van-cell title="经纪人" :value="subdistributorUserName"
                    @click="onCopy({value:subdistributorUserName,text:'经纪人姓名'})"/>
                    <van-cell title="经纪人电话" :value="subdistributorUserPhone"
                    @click="onCopy({value:subdistributorUserPhone,text:'经纪人电话'})"/>
               </div>
               <div class="record-audit__content-action" v-if="(approvalStatus !='MAKEBARGAINCLOSE' && approvalStatus !='RECORDCLOSE') || $has('RECORDORDERCHANGE')">
                    <van-cell 
                    title="订单状态" 
                    :value="getApprovalStatusText(nowApprovalStatus)" 
                    :is-link="recordData.approvalStatus!='RECORDSTART'" 
                    @click="approvalStatusChange"/>
                    <van-cell 
                    title="到访时间" 
                    :value="form.visitTime || '请选择到访时间'" 
                    is-link 
                    v-if="nowApprovalStatus == 'VISIT'"
                    @click="datePickerOpen"/>
                   <div class="item">
                       <span>处理结果：</span>
                       <span class="border-all textarea">
                           <van-field
                            v-model="form.approvalResult"
                            label-width="3em"
                            rows="3"
                            autosize
                            type="textarea"
                            placeholder="处理结果说明"
                            />
                       </span>
                   </div>  
                   <div class="item">
                       <span>上传图片：</span>
                       <span>     
                           <div class="order-images-cover">
                            <div class="order-images-show">
                                    <div class="order-image" v-for="(item,index) in upload" :key="index" @click="imageShowOpen(index)">
                                        <img :src="item|$imageUrl" alt="">
                                        <div class="close-btn" v-if="$role('report')"><van-icon name="cross" class="delete-icon" @click.stop="deleteImage(index)"/></div>
                                    </div>
                                    <van-uploader 
                                    accept="image/*"
                                    :after-read="afterRead"/>
                                </div>
                            </div>
                        </span>
                   </div>
                    <div class="item center" v-if="(recordData.approvalStatus=='RECORDSTART' || recordData.approvalStatus=='RECORDSTANDBY') && $role(['report','manage'])">
                       <span>是否驳回：</span>
                       <div class="btn-cover">
                           <van-switch v-model="form.reback" size="6.6667vw" :active-value="1" :inactive-value="0"/>
                       </div>
                   </div>
                   <div class="item center" v-if="recordData.approvalStatus=='RECORDSTART'">
                       <span>状态选择：</span>
                       <div class="btn-cover">
                           <div 
                           class="btn"
                           :class="{active:form.approvalStatus=='RECORDSTANDBY'}" 
                           @click="approvalStatusChangeFast('RECORDSTANDBY')">处理中</div> 
                           <div 
                           class="btn"
                           :class="{active:form.approvalStatus=='RECORDCOMPLETE'}" 
                           @click="approvalStatusChangeFast('RECORDCOMPLETE')">已报备</div>
                           <div
                            :class="{active:form.approvalStatus=='RECORDCLOSE'}"
                            @click="approvalStatusChangeFast('RECORDCLOSE')"  
                            class="btn"
                           >报备无效</div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       </div>
       <van-popup 
       v-model="showPicker" 
       position="bottom" 
       safe-area-inset-bottom
       close-on-popstate>
        <van-picker
            show-toolbar
            title="选择报备状态"
            ref="recordPicker"
            :columns="recordStatusList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
            :default-index="defaultIndex"
        />
        </van-popup>
         <van-popup 
         v-model="showDatePicker" 
         safe-area-inset-bottom
         close-on-popstate 
         position="bottom"
         >
            <van-datetime-picker
                v-model="currentTime"
                type="datetime"
                title="选择到访时间"
                :min-date="minDate"
                :max-date="maxDate"
                @cancel="closeDatePicker"
                @confirm="selectDate"
                :formatter="formatter"
            />
            </van-popup>
        <!-- 图片预览 -->
        <van-image-preview 
        v-model="imageShow" 
        :images="images" 
        :start-position="imageIndex"
        :max-zoom="3"></van-image-preview>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import imageUploadMixin from "@/mixins/imageUpload";
import getStatusMixin from "@/mixins/getStatusText";
import api from "@/api";
import {findIndex,dateChange,pickerFormatter,isObjectNone,isArray} from '@/utils'
import ossUpload from "@/utils/ossUpload";
export default {

    mixins:[backMixin,imageUploadMixin,getStatusMixin],

    data(){

        return{

            recordTypeTitle:'订单审核',

            form:{

                approvalStatus:'RECORDSTART',

                visitTime:'',

                reback:0

            },

            recordData:{},

            //是否初始化完毕

            inited:false,

            //是否显示报备选择弹窗

            showPicker:false,

            //是否显示时间弹窗

            showDatePicker:false,

            //默认选择

            defaultIndex:0,

            minDate: new Date(2020, 0, 1),

            maxDate: new Date(2025, 10, 1),

            currentTime:new Date(),

            imageShow:false,

            images:[],

            imageIndex:0

        }

    },

    computed:{

        rightTypeBtn(){

            return this.inited 
            
            ? this.recordData.approvalStatus ? 
            
            (this.recordData.approvalStatus  == 'RECORDCLOSE' || this.recordData.approvalStatus  == 'MAKEBARGAINCLOSE') &&  this.$store.state.userInfo.role != 'AUDITORADMIN'   ? '' 

            : this.recordData.approvalStatus  == 'RECORDSTART'

            ? '审核' :'修改'
            
            :''
            
            :''

        },

        approvalStatusPermission(){

            return !!this.recordData?.approvalStatusPermission;

        },

        upload(){

            return this.form.upload || [];

        },


        //楼盘名称

        houseName(){

            return this.inited ? this.recordData.houseName || '暂无' : '';

        },     
        
        //报备状态
        
        approvalStatus(){

            return this.inited ? this.recordData.approvalStatus : '';

        },

        nowApprovalStatus(){

            return this.inited ? this.form.approvalStatus : '';

        },

        //报备时间

        createTime(){

            return this.inited ? this.recordData.createdAt || '...' : '';

        },

        //用户名称

        customerName(){

            return this.inited ? this.recordData?.customerInfo.name || '...' : '';

        },

        //用户手机号码

        customerPhone(){

            return this.inited ? this.recordData?.customerInfo.phone || '...' : '';

        },

        //客户购房资质列表

        customerPic(){

            return this.recordData?.customerInfo?.customerPic || []; 

        },
        
        //用户性别

        customerSex(){

            return this.inited ? this.recordData?.customerInfo.sex || '...' : '';

        },

        //预计到访时间

        estimatedVisitTime(){

            return this.inited ? this.recordData.estimatedVisitTime || '...' :''

        },

        //到访人数

        visitorNum(){

            return this.inited ? this.recordData.visitorNum || 1 :'';

        },

        //分销公司名称

        distributorName(){

            return this.inited ? this.recordData.subdistributorName || '...' : '';

        },

        //备注

        reception(){

            return this.inited ? this.recordData.reception || '...' : '';

        },

        //经纪人

        subdistributorUserName(){

            return this.inited ? this.recordData.subdistributorUserName || '...' : '';

        },


        //经纪人手机号码

        subdistributorUserPhone(){

            return this.inited ? this.recordData.subdistributorUserPhone || '...' : '';


        },

        //报备状态列表

        recordStatusList(){

            return this.inited ? this.recordData.recordStatusList || [] : [];

        },

        //是否为管理员

        role(){

            return this.$store.state.userInfo?.distributorStaffType || '';

        },


        //带看人

        dkName(){

            return this.inited ? this.recordData.dkName || '...' : '';

        },

        //带看人手机号码

        dkPhone(){

            return this.inited ? this.recordData.dkPhone || '...' : '';

        },

        //公司主体

        companyName(){

            return this.$store.state.userInfo?.userCompany?.description;

        }


    },

    methods:{

        //图片上传

        afterRead(file){

            const _check = this.beforeThumbUpload(file.file);

            if(!_check) return;
            ossUpload(file.file).then(res=>{
                if(!this.form.upload){
                    this.$set(this.form,'upload',[]);
                }
                this.form.upload.push(res.data);
            })
            .catch(res=>{
                console.log(res)
                this.$notify({ type: 'danger', message: res || '图片上传失败，请稍后重试' });
            })

        },

        //图片删除

        deleteImage(index){

            this.$dialog.confirm({

                title:"图片删除",

                message:'确定要删除此图片吗'

            }).then(()=>{

                this.form.upload.splice(index,1);

            }).catch(()=>{})

        },

        //预览

        imageShowOpen(index,list){

            //获取当前选择的图片

            if(isArray(list)){

                this.images = list.map(el=>{

                    return this.$getImageUrl(el);

                });

            }else{

                this.images = this.form.upload.map(el=>{

                    return this.$getImageUrl(el);

                });

            }

            if(this.images.length == 0) return;

            this.imageIndex = index;

            this.imageShow = true;

        },

        //快速处理报备单

        approvalStatusChangeFast(type){
            //在选择了驳回报备单时，不允许显示选择此按钮
            if(!!this.form?.reback){
                this.$toast('选择驳回报备单时\n报备单状态只能为报备无效');
                return;
            }
            this.form.approvalStatus = type;
        },

        //打开报备状态修改窗口

        approvalStatusChange(){

            if(!this.approvalStatus || this.approvalStatus == 'RECORDSTART' || !!this.form?.reback) return;
            
            this.showPicker=true;

        },

        //选中对象

        onConfirm(val){

            this.form.approvalStatus=val.value;

            this.showPicker=false;

        },


        //获取报备单数据

        getRecordOrderData(){

            this.$toast.loading({

                message:'加载中...',

                duration:0,

                overlay:true

            })

            api.getRecordAuditData({

                options:{id:this.$route.params.id},

                success:(res)=>{

                    if(res.type == 200){

                        this.recordData = res.data;

                        //设置一下form的数据

                        this.form.approvalStatus=res.data.approvalStatus == 'RECORDSTART' ? 'RECORDSTANDBY' : res.data.approvalStatus;

                        if(res.data.visitTime){

                            this.form.visitTime = res.data.visitTime;

                            this.currentTime = new Date(res.data.visitTime);

                        }

                        if(res.data.upload){

                            this.$set(this.form,'upload',res.data.upload)

                            this.form.upload = res.data.upload;

                        }

                        //获取对应的对象

                        let _index = findIndex(this.recordData.recordStatusList,el=>{

                            return el.value == this.form.approvalStatus;

                        });

                        if(_index != -1){

                            this.defaultIndex = _index;

                        }

                   

                        this.inited=true;

                    }else{

                        this.$dialog.alert({

                            title:'获取数据失败',

                            message:res.msg || '获取订单信息失败，请稍后重试'

                        });

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'获取数据失败',

                        message:'订单信息获取异常，请检查网络或请稍后重试'

                    });

                },

                complete: ()=> {

                    this.$toast.clear();
                    
                }

            })

        },
        
        //提交报备单

        submitRecordData(){

            if(this.form.approvalStatus == 'VISIT' && !this.form.visitTime){

                this.$toast('请选择到访时间');

                return;

            }


            this.$dialog.confirm({

                title:`${this.recordData.approvalStatus == 'RECORDSTART' ? '审核' : '修改'}订单`,

                message:`确定要更改订单状态？${this.form.approvalStatus == 'RECORDCLOSE' || this.form.approvalStatus == 'MAKEBARGAINCLOSE' ? '提交后，报备单将会关闭，' : ''}此操作不可逆`,

                confirmButtonColor:"#00a0e4"
                

            }).then(()=>{

                let formData = {id:this.recordData.id};

                formData = Object.assign(formData,this.form);

                this.$toast.loading({

                    message:'提交中...',

                    duration:0,

                    overlay:true

                })

                api.submitRecordAuditData({

                    options:formData,

                    success:(res)=>{

                        if(res.type == 200){

                            this.$dialog.alert({

                                title:'提交成功',

                                message:"订单数据提交成功",

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{

                                this.$store.commit('setRouterData',{key:"recordListRefresh",value:formData.id});

                                this.$router.back();

                            })

                        }else{
                            
                            this.$dialog.alert({

                                title:'提交失败',

                                message:res.msg || "订单数据提交失败，请稍后重试"

                            })

                        }

                    },

                    error:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'提交失败',

                            message:"订单数据提交异常，请稍后重试"

                        })

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

                })


            }).catch(()=>{})


        },

        //关闭时间弹窗


        closeDatePicker(){

            this.showDatePicker=false;

        },

        selectDate(val){

            this.$set(this.form,'visitTime',dateChange(val));

            this.closeDatePicker();


        },

        datePickerOpen(){
            
            this.showDatePicker=true;

        },

        //时间区间格式化

        formatter (type, value) {
            
            return pickerFormatter(type,value);

        },



        //点击复制

        onCopy(item){

            if(isObjectNone(this.recordData)) return;

            let copyMessage = '';

            if(item.type == 'all'){
                let estimatedVisitTime = !!this.recordData.estimatedVisitTime? (this.recordData.estimatedVisitTime.split(' '))[0] : '暂无';
                if(this.recordData.yiji){
                     copyMessage = `一级公司名称:${this.recordData?.copyCompanyName || this.recordData.subdistributorName}\n带看人员：${this.recordData.dkName}\n员工电话：${this.recordData.dkPhone}\n客户姓名：${this.recordData.customerInfo.name}\n客户电话：${this.recordData.customerInfo.phone}\n客户性别：${this.recordData.customerInfo.sex == 1 ?'男':'女'}\n到访人数：${this.recordData.visitorNum}\n带看项目：${this.recordData.houseName}\n到访时间：${estimatedVisitTime}\n指定接待：${this.recordData.reception || '暂无'}`
                }else{
                    copyMessage = `一级公司名称:${this.recordData?.yijiCompanyName ? this.recordData?.yijiCompanyName : this.companyName}\n二级公司全称：${this.recordData.copyCompanyName || this.recordData?.distributor?.companyLicenseName || '暂无'}\n带看人员：${this.recordData.dkName}\n员工电话：${this.recordData.dkPhone}\n客户姓名：${this.recordData.customerInfo.name}\n客户电话：${this.recordData.customerInfo.phone}\n客户性别：${this.recordData.customerInfo.sex == 1 ?'男':'女'}\n到访人数：${this.recordData.visitorNum}\n带看项目：${this.recordData.houseName}\n到访时间：${estimatedVisitTime}\n指定接待：${this.recordData.reception || '暂无'}`
                }

            }else{
                let value = ''
                if(item.key=='estimatedVisitTime'){
                    value = !!item.value? (item.value.split(' '))[0] : '暂无';
                }else{
                    value = item.value;
                }
                copyMessage = `${value}`;
            }

           

            this.$copyText(copyMessage).then(()=>{
                
                this.$toast({

                    message:`${item.type == 'all' ? '报备格式' :item.text}成功复制到剪辑板`,

                    position:"bottom"

                });
    
                },()=>{
                
                this.$toast({

                    message:'复制失败，如果多次复制失败，请联系管理员',

                    position:'bottom'

                });

            })

        },










        //初始化方法

        init(){

            this.getRecordOrderData();

        }

    },

    watch:{

        'form.reback'(val){

            if(val){

                this.$toast({
                    duration:4000,
                    message:'驳回报备单时，请在审核意见中填写驳回原因，并告知分销可立即重新报备'
                })

                this.$set(this.form,'approvalStatus','RECORDCLOSE')

            }else{

                this.$set(this.form,'approvalStatus','RECORDSTANDBY')

            }

        }

    },

    created(){

        this.$nextTick(()=>{

            this.init();

        })

    }

}
</script>

<style lang="less">
.record-audit-wrapper{

    height:100%;

    .fiexd-cover{

        position: fixed;

        top:0;

        left:0;

        right:0;

        bottom:0;

    }

    .record-audit-cover{

        height:100%;

        .bd{

            flex:1;

            height:0;

            overflow: hidden;

            overflow-y:auto;

            padding:0 0 50px;

            .img-cover{

                display: flex;

                flex-wrap:wrap;

                padding: 10px 0;

                .img-item{

                    width: 80px;

                    height:80px;

                    margin-right:7px;

                    margin-bottom:10px;

                    &:nth-child(4n){

                        margin-right:0;

                    }

                    img{

                        width: 100%;

                        height:100%;

                    }

                }

            }

            .record-audit__content-title{

                display: flex;

                justify-content: space-between;

                align-items: center;

                padding: 15px 16px;

                .house-name{

                    font-size:18px;

                }

                .recordType{

                    padding: 0 10px 0;

                    height:24px;
                    
                    line-height:26px;

                    //待处理

                    &.await{

                        color:#00a0e4;

                        background: #f3f9ff;

                        &:after{

                            border-color:#00a0e4;

                        }

                    }

                    //已处理

                    &.over{

                        color:#ff4a04;

                        background: #fff7f3;

                        &:after{

                            border-color:#ff4a04;

                        }

                    }

                    //已到访

                    &.visit{

                        color:#dfd156;

                        background:rgb(255, 254, 211);

                        &:after{

                            border-color:#e4cd00;

                        }


                    }
                    
                    //已成交

                    &.ctd{

                        color:#00e41e;

                        background: #ddffeb;

                        &:after{

                            border-color:#00e41e;

                        }

                    }

                    //确认成交

                    &.ctdC{

                        color:#64ff78;

                        background: #ddffeb;

                        &:after{

                            border-color:#00ff22;

                        }


                    }
                    

                    //已关闭

                    &.end{
                        
                        color:#ccc;

                        background: #f8f8f8;

                        &:after{

                            border-color:#ccc;

                        }


                    }

                    

                }

            }
            
            
            .record-audit__content-action{


                .item{

                    line-height:28px;

                    font-size:15px;

                    display: flex;

                    span:first-child{

                        color:#999;

                    }

                    span:nth-child(2){

                        flex:1;

                        width: 0;

                    }

                }

            }

            .record-audit__content-action{

                padding: 0;

                margin-top:15px;

                border-top:10px solid #f6f6f6;

                .item{

                    align-items: flex-start;
                    
                    padding: 30px 16px 0;

                    &.center{

                       align-items: center;

                    }

                    &:first-child{

                        padding-top:0;

                    }

                    .textarea:after{

                        border-radius:5px;

                    }

                    .van-cell{

                        z-index:20;

                        background-color: transparent;

                    }

                    .order-images-cover{

                        background:#fff;

                        .order-images-show{

                            display: flex;
                            
                            flex-wrap: wrap;

                        }

                        .order-image{

                            width: 80px;

                            height: 80px;

                            margin-bottom:10px;

                            margin-right:8px;

                            box-shadow:0 5px 16px rgba(0,0,0,.08);

                            position: relative;

                            overflow: hidden;

                            img{

                                width: 100%;

                                height:100%;

                            }

                            .close-btn{

                                position: absolute;
                                width: 80px;
                                height: 40px;
                                right: -60px;
                                top: -25px;
                                background: rgba(0, 0, 0, 0.6);
                                text-align: center;
                                padding-top: 17px;
                                transform: translateY(10px) translateX(-26px) rotate(45deg);

                                color:#fff;

                                .delete-icon{

                                    font-size:15px;

                                    text-shadow: 0 0 16px rgba(0,0,0,.16);

                                    transform: rotate(-45deg);

                                }
                                
                                &:active .delete-icon{

                                    opacity: .6;

                                }

                            }

                        }

                        .van-uploader__upload{

                            width: 80px;

                            height:80px;
                            

                            .van-uploader__upload-icon{

                                font-size:24px;

                            }

                        }

                    }

                    .btn-cover{

                        display: flex;

                        .btn{

                            padding: 5px 0 4px;

                            width: 80px;

                            text-align: center;

                            color:#00a0e4;

                            position: relative;

                            z-index:25;
                            
                            border:1px solid #00a0e4;

                            font-size:14px;

                            &:first-child{

                                border-radius: 44px 0 0 44px;

                                border-right:0;

                            }
                            
                            &:last-child{

                                border-left:0;

                                border-radius: 0 44px 44px 0;

                            }
                          

                            &.active{


                                background: #00a0e4;

                                color:#fff;

                            }

                        }

                    }

                }

            }

        }

        

    }

}
</style>